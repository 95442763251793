import moment from 'moment';

const CTRL_IRAP = 0
const CTRL_IRAC = 1
const CTRL_RS485AP = 2
const CTRL_RS485AC = 3
const CTRL_RS485RELAY = 4
const CTRL_RS485MODBUS = 5
const CTRL_RS485ST = 6 //sterilizer
const CTRL_RS485O2 = 7 //o2 generator
const CTRL_RS485AIRCON = 8
const CTRL_RS485CHAIR = 9
//const CTRL_IRAIRCON = 10
const CTRL_IRAIRCONINV = 10   //inverter airocn
const CTRL_IRAIRCONCON = 11   //constant speed aircon
const CTRL_WIFIDIRECT = 12   //KT살균기 등 WiFi간 통신
const CTRL_D = 13   //tbd
const CTRL_E = 14   //tbd
const CTRL_RS485MONITOR = 15   //monitor device

const DEVICE_MANUF_SS = 0
const DEVICE_MANUF_LG = 1
const DEVICE_MANUF_CARRIER = 2
const DEVICE_MANUF_DK = 3
const DEVICE_MANUF_SANGSHIN = 4
const DEVICE_MANUF_HANAENERTECH = 5
const DEVICE_MANUF_HUEANDTECH = 6
const DEVICE_MANUF_TIZEN = 7
const DEVICE_MANUF_MODBUS = 8
const DEVICE_MANUF_YULIM = 9
const DEVICE_MANUF_AT = 10
const DEVICE_MANUF_AUTOREX = 11
const DEVICE_MANUF_YJ = 12
const DEVICE_MANUF_ANYTECH = 13
const DEVICE_MANUF_OXUS = 14
const DEVICE_MANUF_WAVESHARE = 15  //신규 Relay (Modbus)
const DEVICE_MANUF_LG2 = 16  //LG 신규에어컨의 경우 Leader Code가 기존과 다름.
const DEVICE_MANUF_NEXTONE = 17  //Nextone 온열의자
const DEVICE_MANUF_JUNGMIN = 18 //정민 순환기
const DEVICE_MANUF_WITHMOTION = 19 //위드모션 진동미터
const DEVICE_MANUF_JSE = 20 //전류미터 Jiangsu Sfere Electric Co., Ltd

//RELAY에 연결된 디바이스 정의
const RELAY_DEVICE_NOTDEFINED = 0       //등록되지 않은 경우
const RELAY_DEVICE_AUTODOOR2 = 1        //자동문2
const RELAY_DEVICE_AIRCON = 2           //냉난방기 실외기
const RELAY_DEVICE_AUTODOOR1 = 3        //자동문내부스위치
const RELAY_DEVICE_AUTODOOROUTSWCON = 4 //출입통제
const RELAY_DEVICE_AIRPURIFIER = 5      //공기청정기
const RELAY_DEVICE_AIRSTERILIZER = 6    //공기살균기
const RELAY_DEVICE_O2GENERATOR = 7      //산소발생기
const RELAY_DEVICE_BUSSTERILIZER = 8    //버스살균기
const RELAY_DEVICE_LIGHT = 9            //조명
const RELAY_DEVICE_AC = 10              //공기순환기
const RELAY_DEVICE_AUTODOOR3 = 11       //자동문3
const RELAY_DEVICE_AUTODOOR4 = 12       //자동문4

//RELAY접점타입
const RELAY_OPTION_NC = 1   //Normal Close
const RELAY_OPTION_AUTO = 2 //Auto Mode가 존재함

const MONITOR_NONE = 0
const MONITOR_O2 = 1
const MONITOR_VIBRATION = 2
const MONITOR_CURRENT = 3

//OFF CODE FORMAT
//FFFF 0000
//type:type:type:type nc:nc:nc:nc
// DEVICE TYPE
//---------------------------------------------
//      1st      | 2nd      | 3rd     | 4th
//      #0 dev   | #1 dev   | #2 dev  | #3 dev 
//---------------------------------------------

// OPTIONS
//----------------------------
//      3   | 2   | 1   | 0
//      x   | x   | auto| nc 
//----------------------------

//cmd list
const CMD_APON = 1
const CMD_APOFF = 2
const CMD_APAUTO = 8
const CMD_ACON = 3
const CMD_ACOFF = 4
const CMD_ACAUTO = 10

//Error 정의
const ERROR_COM = 0x1    //통신에러
const ERROR_POWER = 0x2  //전원에러
const ERROR_MASK = 0xf   //ERROR MASK

//alarm 정의
const ALARM_FILTER = 0x10  //필터알람
const ALARM_MOTOR = 0x20   //모터알람
const ALARM_SENSOR = 0x40  //센서알람
const ALARM_DOOR = 0x80    //도어알람
const ALARM_MASK = 0xf0    //ALARM MASK

const apc = {
    install(Vue) {

        Vue.prototype.$apc = {};

        Vue.prototype.$apc.CTRL_IRAP = CTRL_IRAP
        Vue.prototype.$apc.CTRL_IRAC = CTRL_IRAC
        Vue.prototype.$apc.CTRL_RS485AP = CTRL_RS485AP
        Vue.prototype.$apc.CTRL_RS485AC = CTRL_RS485AC
        Vue.prototype.$apc.CTRL_RS485RELAY = CTRL_RS485RELAY
        Vue.prototype.$apc.CTRL_RS485MODBUS = CTRL_RS485MODBUS
        Vue.prototype.$apc.CTRL_RS485ST = CTRL_RS485ST
        Vue.prototype.$apc.CTRL_RS485O2 = CTRL_RS485O2
        Vue.prototype.$apc.CTRL_RS485AIRCON = CTRL_RS485AIRCON
        Vue.prototype.$apc.CTRL_RS485CHAIR = CTRL_RS485CHAIR
        //Vue.prototype.$apc.CTRL_IRAIRCON = CTRL_IRAIRCON
        Vue.prototype.$apc.CTRL_IRAIRCONINV = CTRL_IRAIRCONINV
        Vue.prototype.$apc.CTRL_IRAIRCONCON = CTRL_IRAIRCONCON
        Vue.prototype.$apc.CTRL_WIFIDIRECT = CTRL_WIFIDIRECT
        Vue.prototype.$apc.CTRL_RS485MONITOR = CTRL_RS485MONITOR
        
        //relay device list
        Vue.prototype.$apc.RELAY_DEVICE_NOTDEFINED = RELAY_DEVICE_NOTDEFINED  //등록되지 않은 경우
        Vue.prototype.$apc.RELAY_DEVICE_AUTODOOR2 = RELAY_DEVICE_AUTODOOR2  //자동문2
        Vue.prototype.$apc.RELAY_DEVICE_AIRCON = RELAY_DEVICE_AIRCON           //냉난방기 실외기
        Vue.prototype.$apc.RELAY_DEVICE_AUTODOOR1 = RELAY_DEVICE_AUTODOOR1     //자동문1
        Vue.prototype.$apc.RELAY_DEVICE_AUTODOOROUTSWCON = RELAY_DEVICE_AUTODOOROUTSWCON //출입통제
        Vue.prototype.$apc.RELAY_DEVICE_AIRPURIFIER = RELAY_DEVICE_AIRPURIFIER      //공기청정기
        Vue.prototype.$apc.RELAY_DEVICE_AIRSTERILIZER = RELAY_DEVICE_AIRSTERILIZER    //공기살균기
        Vue.prototype.$apc.RELAY_DEVICE_O2GENERATOR = RELAY_DEVICE_O2GENERATOR      //산소발생기
        Vue.prototype.$apc.RELAY_DEVICE_BUSSTERILIZER = RELAY_DEVICE_BUSSTERILIZER    //버스살균기
        Vue.prototype.$apc.RELAY_DEVICE_LIGHT = RELAY_DEVICE_LIGHT            //조명
        Vue.prototype.$apc.RELAY_DEVICE_AC = RELAY_DEVICE_AC              //공기순환기
        Vue.prototype.$apc.RELAY_DEVICE_AUTODOOR3 = RELAY_DEVICE_AUTODOOR3              //자동문3
        Vue.prototype.$apc.RELAY_DEVICE_AUTODOOR4 = RELAY_DEVICE_AUTODOOR4              //자동문4
        
        //manufacturer list
        Vue.prototype.$apc.DEVICE_MANUF_SS = DEVICE_MANUF_SS
        Vue.prototype.$apc.DEVICE_MANUF_LG = DEVICE_MANUF_LG
        Vue.prototype.$apc.DEVICE_MANUF_CARRIER = DEVICE_MANUF_CARRIER
        Vue.prototype.$apc.DEVICE_MANUF_DK = DEVICE_MANUF_DK
        Vue.prototype.$apc.DEVICE_MANUF_SANGSHIN = DEVICE_MANUF_SANGSHIN
        Vue.prototype.$apc.DEVICE_MANUF_HANAENERTECH = DEVICE_MANUF_HANAENERTECH
        Vue.prototype.$apc.DEVICE_MANUF_HUEANDTECH = DEVICE_MANUF_HUEANDTECH
        Vue.prototype.$apc.DEVICE_MANUF_TIZEN = DEVICE_MANUF_TIZEN
        Vue.prototype.$apc.DEVICE_MANUF_MODBUS = DEVICE_MANUF_MODBUS
        Vue.prototype.$apc.DEVICE_MANUF_YULIM = DEVICE_MANUF_YULIM
        Vue.prototype.$apc.DEVICE_MANUF_AT = DEVICE_MANUF_AT
        Vue.prototype.$apc.DEVICE_MANUF_AUTOREX = DEVICE_MANUF_AUTOREX
        Vue.prototype.$apc.DEVICE_MANUF_YJ = DEVICE_MANUF_YJ
        Vue.prototype.$apc.DEVICE_MANUF_ANYTECH = DEVICE_MANUF_ANYTECH
        Vue.prototype.$apc.DEVICE_MANUF_OXUS = DEVICE_MANUF_OXUS
        Vue.prototype.$apc.DEVICE_MANUF_WAVESHARE = DEVICE_MANUF_WAVESHARE
        Vue.prototype.$apc.DEVICE_MANUF_LG2 = DEVICE_MANUF_LG2
        Vue.prototype.$apc.DEVICE_MANUF_NEXTONE = DEVICE_MANUF_NEXTONE
        Vue.prototype.$apc.DEVICE_MANUF_JUNGMIN = DEVICE_MANUF_JUNGMIN
        Vue.prototype.$apc.DEVICE_MANUF_WITHMOTION = DEVICE_MANUF_WITHMOTION
        Vue.prototype.$apc.DEVICE_MANUF_JSE = DEVICE_MANUF_JSE
        
        //monitor device
        Vue.prototype.$apc.MONITOR_NONE = MONITOR_NONE
        Vue.prototype.$apc.MONITOR_O2 = MONITOR_O2
        Vue.prototype.$apc.MONITOR_VIBRATION = MONITOR_VIBRATION
        Vue.prototype.$apc.MONITOR_CURRENT = MONITOR_CURRENT

        //cmd list
        Vue.prototype.$apc.CMD_APON = CMD_APON
        Vue.prototype.$apc.CMD_APOFF = CMD_APOFF
        Vue.prototype.$apc.CMD_APAUTO = CMD_APAUTO
        Vue.prototype.$apc.CMD_ACON = CMD_ACON
        Vue.prototype.$apc.CMD_ACOFF = CMD_ACOFF
        Vue.prototype.$apc.CMD_ACAUTO = CMD_ACAUTO

        //error list
        Vue.prototype.$apc.ERROR_COM = ERROR_COM
        Vue.prototype.$apc.ERROR_POWER = ERROR_POWER
        Vue.prototype.$apc.ERROR_MASK = ERROR_MASK
        
        Vue.prototype.$apc.ALARM_FILTER = ALARM_FILTER
        Vue.prototype.$apc.ALARM_MOTOR = ALARM_MOTOR
        Vue.prototype.$apc.ALARM_SENSOR = ALARM_SENSOR
        Vue.prototype.$apc.ALARM_DOOR = ALARM_DOOR
        Vue.prototype.$apc.ALARM_MASK = ALARM_MASK
        
        Vue.prototype.$apc.isSumtur = function () {
            let url = Vue.prototype.$common.getUrl().url;
            return url == Vue.prototype.$common.URL_SUMTUR_MISEWATCH;
        }

        Vue.prototype.$apc.isShelter = function () {
            let url = Vue.prototype.$common.getUrl().url;
            return url == Vue.prototype.$common.URL_SHELTER_MISEWATCH || url == Vue.prototype.$common.URL_MY_SMART_BUS || url == Vue.prototype.$common.URL_PORTAL_SMART_BUS;
        }

        Vue.prototype.$apc.deviceTitle = function (airs) {
            let title = [];
            
            for(let i=0; i<airs.length; i++) {
                if(Object.keys(airs[i]).includes("irCode")) {
                    let custom = airs[i].irCode;
                    //custom = parseInt(custom, 16);
                    
                    //let type = ((custom >> 12) & 0xf);
                    let type = this.apcCtrlMethod(custom);
                    //let manufacturer = (custom >> 4) & 0xff;
                    let manufacturer = this.apcManufacturer(custom);

                    //ap
                    if(type==CTRL_IRAP || type==CTRL_RS485AP) {
                        if(!title.includes("공기청정기")) title.push("공기청정기");
                    }

                    //ac
                    if(type==CTRL_IRAC || type==CTRL_RS485AC) {
                        if(!title.includes("공기순환기")) title.push("공기순환기");
                    }
                    
                    //relay
                    if(type==CTRL_RS485RELAY) {
                        if(!title.includes("릴레이")) title.push("릴레이");
                    }

                    //aircon
                    if(type==CTRL_RS485AIRCON || type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                        if(!title.includes("냉난방기")) title.push("냉난방기");
                    }
                }
            }

            //console.log("title="+title.toString());

            return title.toString();
        }

        Vue.prototype.$apc.deviceName = function (air) {
            let name = {name:'', short:''};
            
            if(Object.keys(air).includes("irCode")) {
                let custom = air.irCode;
                //custom = parseInt(custom, 16);
                
                //let type = ((custom >> 12) & 0xf);
                let type = this.apcCtrlMethod(custom);
                //let manufacturer = (custom >> 4) & 0xff;
                let manufacturer = this.apcManufacturer(custom);

                //ap
                if(type==CTRL_IRAP || type==CTRL_RS485AP) {
                    name.short = "청<br>정<br>기";
                    name.name = "공기청정기";
                }

                //ac
                if(type==CTRL_IRAC || type==CTRL_RS485AC) {
                    name.short = "순<br>환<br>기";
                    name.name = "공기순환기";
                }
                
                //relay
                if(type==CTRL_RS485RELAY) {
                    name.short = "릴<br>레<br>이";
                    name.name = "릴레이";
                }

                //aircon
                if(type == CTRL_RS485AIRCON || type == CTRL_IRAIRCONINV || type == CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                    name.short = "냉<br>난<br>방";
                    name.name = "냉난방기";
                }

                //chair
                if(type == CTRL_RS485CHAIR) {
                    let apcChairType = this.apcChairType(air.irOnCode);
                    //console.log("apcChairType="+apcChairType);
                    if(apcChairType == 1) {
                        name.short = "냉온<br>의자";
                        name.name = "냉온의자";
                    } else {
                        name.short = "온열<br>의자";
                        name.name = "온열의자";
                    }
                }
            }

            //console.log("title="+title.toString());

            return name;
        }

        Vue.prototype.$apc.gradeApcCount = function (grades) {
            //console.log("gradeApcCount "+JSON.stringify(grades));

            let ap = {total:0,totalRs485:0};
            let ac = {total:0,totalRs485:0};
            let st = {total:0,totalRs485:0};
            let o2 = {total:0,totalRs485:0};
            let chair = {total:0,totalRs485:0};
            let relay = {total:0,totalRs485:0,button:[]};
            let aircon = {total:0,totalRs485:0};
            
            let totalRs485 = 0;
            let totalApc = 0;

            let totalMwc = 0;

            if(grades && grades.length > 0) {
                //let apcGrade = grades.filter(g => g.classType == 0 && g.mwdDevice && g.apcInfo && g.apcInfo.length > 0);
                let validGrade = grades.filter(g => g.classType == 0 && g.mwdDevice && g.mwdDevice.mwdMac);

                validGrade.forEach(cls => {

                    if(cls.mwdDevice.mwdType == "MWC") {
                        totalMwc++;
                    }

                    if(!cls.apcInfo || cls.apcInfo.length == 0) {
                        return;
                    }

                    let airs = cls.apcInfo;
                    airs.forEach(apc => {
                        let custom = apc.irCode;
                        let type = this.apcCtrlMethod(custom);
                        let manufacturer = this.apcManufacturer(custom);

                        //console.log("air="+JSON.stringify(airs[i]));

                        //ap
                        if(type==CTRL_IRAP || type==CTRL_RS485AP) {
                            ap.total++; 
                            if(type == CTRL_RS485AP) {
                                ap.rs485++;
                                totalRs485++;
                            }
                            totalApc++;
                        }

                        //ac
                        else if(type==CTRL_IRAC || type==CTRL_RS485AC) {
                            ac.total++; 
                            if(type == CTRL_RS485AC) {
                                ac.rs485++;
                                totalRs485++;
                            }
                            totalApc++;
                        }

                        //sterilizer
                        else if(type==CTRL_RS485ST || type==CTRL_WIFIDIRECT) {
                            let slaveId = this.apcSlaveId(apc.irCode, apc.irOnCode);
                            if(slaveId != 0) {
                                st.total++; 
                                if(type == CTRL_RS485ST) {
                                    st.rs485++;
                                    totalRs485++;
                                }
                                totalApc++;
                            }
                        }

                        //o2 generator
                        else if(type==CTRL_RS485O2) {
                            o2.total++; 
                            o2.rs485++;
                            totalRs485++;
                            totalApc++;
                        }

                        //chair
                        else if(type==CTRL_RS485CHAIR) {
                            chair.total++; 
                            chair.rs485++;
                            totalRs485++;
                            totalApc++;
                        }

                        //relay
                        else if(type==CTRL_RS485RELAY) {
                            relay.total++;
                            relay.rs485++;
                            totalRs485++;
                            totalApc++;

                            let b = this.apcRelayInfo(apc)[0];

                            relay.button.push(b);
                        }
                        
                        //aircon
                        else if(type==CTRL_RS485AIRCON || type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                            aircon.total++; 
                            
                            if(type == CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                                aircon.rs485++;
                                totalRs485++;
                            }
                            totalApc++;
                        }
                    });
                });
            }

            let info = { ap, ac, st, o2, chair, relay, aircon };
            info.totalApc = totalApc;
            info.totalRs485 = totalRs485;
            info.totalMwc = totalMwc;
            
            //console.log("gradeApcCount="+JSON.stringify(info));

            return info;
        }

        Vue.prototype.$apc.deviceInfo = function (schoolType, airs) {
            //console.log("deviceInfo "+JSON.stringify(airs));

            let ap = {total:0,on:0,com_error:0,filter_error:0,manualMode:0};
            let ac = {total:0,on:0,com_error:0,filter_error:0,manualMode:0};
            let st = {total:0,on:0,com_error:0,filter_error:0,manualMode:0};
            let o2 = {total:0,on:0,com_error:0,filter_error:0};
            let chair = {total:0,on:0,com_error:0,filter_error:0,sensor_error:0,manualMode:0};
            let relay = {total:0,on:0,com_error:0,filter_error:0,button:[]};
            let aircon = {total:0,totalRS485:0, on:0,com_error:0,filter_error:0,manualMode:0};

            if(airs != null) {

                for(let i=0; i<airs.length; i++) {
                    if(Object.keys(airs[i]).includes("irCode")) {
                        let custom = airs[i].irCode;
                        //custom = parseInt(custom, 16);
                        
                        //let type = ((custom >> 12) & 0xf);
                        let type = this.apcCtrlMethod(custom);
                        //let manufacturer = (custom >> 4) & 0xff;
                        let manufacturer = this.apcManufacturer(custom);

                        //console.log("air="+JSON.stringify(airs[i]));

                        //ap
                        if(type==CTRL_IRAP || type==CTRL_RS485AP) {
                            ap.total++; 
                            if(airs[i].apcError & ERROR_COM) { ap.com_error++; }
                            if(airs[i].apcError & ALARM_FILTER) { ap.filter_error++; }
                            if(airs[i].power == 'Y') { ap.on++; }

                            if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { ap.manualMode++; }
                        }

                        //ac
                        else if(type==CTRL_IRAC || type==CTRL_RS485AC) {
                            ac.total++; 
                            if(airs[i].apcError & ERROR_COM) { ac.com_error++; }
                            if(airs[i].apcError & ALARM_FILTER) { ac.filter_error++; }
                            if(airs[i].power == 'Y') { ac.on++; }

                            if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { ac.manualMode++; }
                        }

                        //sterilizer
                        else if(type==CTRL_RS485ST || type==CTRL_WIFIDIRECT) {
                            let slaveId = this.apcSlaveId(airs[i].irCode, airs[i].irOnCode);
                            if(slaveId != 0) {
                                st.total++; 
                                if(airs[i].apcError & ERROR_COM) { st.com_error++; }
                                if(airs[i].apcError & ALARM_FILTER) { st.filter_error++; }
                                if(airs[i].power == 'Y') { st.on++; }

                                if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { st.manualMode++; }
                            }
                        }

                        //o2 generator
                        else if(type==CTRL_RS485O2) {
                            o2.total++; 
                            if(airs[i].apcError & ERROR_COM) { o2.com_error++; }
                            if(airs[i].apcError & ALARM_FILTER) { o2.filter_error++; }
                            if(airs[i].power == 'Y') { o2.on++; }
                        }

                        //chair
                        else if(type==CTRL_RS485CHAIR) {
                            chair.total++; 
                            if(airs[i].apcError & ERROR_COM) { chair.com_error++; }
                            if(airs[i].apcError & ALARM_FILTER) { chair.filter_error++; }
                            if(airs[i].apcError & ALARM_SENSOR) { chair.sensor_error++; }
                            //if(airs[i].apcLevel & 3) { chair.on++; }
                            if(airs[i].power == 'Y') { chair.on++; }

                            if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { chair.manualMode++; }
                        }

                        //relay
                        else if(type==CTRL_RS485RELAY) {
                            //relay.total++; 
                            //relay.button = this.apcRelayInfo(airs[i]).relay;
                            let b = this.apcRelayInfo(airs[i])[0];
                            //console.log("button="+JSON.stringify(relay.button));
                            
                            //relay.button.push(button);
                            /*
                            if(schoolType == 3) {
                                relay.total += 1;     
                            } else {
                                relay.total += relay.button.length;
                                //relay.total += 1;
                            }
                            */
                            
                            /*
                            relay.total += relay.button.length;
                            relay.button.forEach(b => {
                                if(b.value == true) {
                                    relay.on++;
                                }
                            });
                            */

                            relay.total++;

                            /*
                            let opCount = 0;
                            relay.button.forEach(b => {
                                if(b.op == true) {
                                    opCount++;
                                }
                            });
                            //접점중 한개라도 relay가 동작하고 있으면 동작중 표시
                            if(opCount) {
                                relay.on++;
                            }
                            */

                            if(airs[i].power == 'Y') { relay.on++; }


                            if(b.com_error == undefined) b.com_error = 0; 
                            if(airs[i].apcError & ERROR_COM) { 
                                b.com_error++; 
                            }

                            if(b.filter_error == undefined) b.filter_error = 0;
                            if(airs[i].apcError & ALARM_FILTER) { 
                                b.filter_error++; 
                            }

                            //relay.subdevice = relay.button.filter(x => {
                            //    return (x.name !== '');
                            //});
                            //if(airs[i].power == 'Y') { relay.on++; }
                            
                            if(b.devIdx == RELAY_DEVICE_AUTODOOROUTSWCON) {
                                if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { b.manualMode++; }
                            } else {
                                b.manualMode = 0;
                            }

                            relay.button.push(b);
                        }
                        
                        /*
                        let totalRelay = airs.filter(air => {
                            if(Object.keys(air).includes("irCode")) {
                                return air.irCode.substring(0,1)==CTRL_RS485RELAY;
                            }
                            return false;
                        });
                        let onRelay = totalRelay.filter(air => {
                            return air.power == 'Y'
                        });
                        relay.total = totalRelay.length;
                        relay.on = onRelay.length;
                        */

                        //aircon
                        else if(type==CTRL_RS485AIRCON || type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                            aircon.total++; 
                            if(airs[i].apcError & ERROR_COM) { aircon.com_error++; }
                            if(airs[i].apcError & ALARM_FILTER) { aircon.filter_error++; }
                            if(type==CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) aircon.totalRS485++;
                            
                            if(airs[i].power == 'Y') { aircon.on++; }

                            if(airs[i].apcRunMode == 0 || airs[i].apcRunMode == 1) { aircon.manualMode++; }
                        }
                        
                        /*
                        let totalAircon = airs.filter(air => {
                            if(Object.keys(air).includes("irCode")) {
                                return air.irCode.substring(0,1)==CTRL_RS485AIRCON || air.irCode.substring(0,1)==CTRL_IRAIRCON;
                            }
                            return false;
                        });
                        let onAircon = totalAircon.filter(air => {
                            return air.power == 'Y'
                        });
                        aircon.total = totalAircon.length;
                        aircon.on = onAircon.length;
                        */
                    }
                }
            }

            let dInfo = { ap, ac, st, o2, chair, relay, aircon };
            dInfo.total = ap.total + ac.total + st.total + o2.total + chair.total + relay.total + aircon.total;

            //console.log("dInfo="+JSON.stringify(dInfo));

            return dInfo;
        }

        /* DeviceSetting Menu에서 동작선택으로 2개를 동시에 제어가능한지 체크한다.

            0,1에 등록된 디바이스가 모두 Relay가 아닌 경우이면서 디바이스가 같은 경우에만 동시제어가 가능하다.
        */    
        Vue.prototype.$apc.canCtrlMulty = function (aps, acs) {
            let ret = {total:0, totalAp:0, totalAc:0, totalRelay:0, totalAircon:0, totalRS485Aircon:0, canMultyAp:true, canMultyAc:true};
            let apT0=0, acT0=0, relayT0=0, airconT0=0, airconRS485T0=0;
            let apT1=0, acT1=0, relayT1=0, airconT1=0, airconRS485T1=0;
            
            //console.log("aps="+JSON.stringify(aps)+" acs="+JSON.stringify(acs));

            //Check Purifiers
            if(aps != null) {
                if(Object.keys(aps).includes("relay")) {
                    apT0 = aps.ap.total;
                    acT0 = aps.ac.total;
                    relayT0 = aps.relay.total;
                    airconT0 = aps.aircon.total;
                    airconRS485T0 = aps.aircon.totalRS485;
                } else {
                    let apType = -1;
                    for(let i=0; i<aps.length; i++) {
                        if(Object.keys(aps[i]).includes("irCode")) {
                            let custom = aps[i].irCode;
                            //custom = parseInt(custom, 16);
                            
                            //let type = ((custom >> 12) & 0xf);
                            let type = this.apcCtrlMethod(custom);
                            //let manufacturer = (custom >> 4) & 0xff;
                            let manufacturer = this.apcManufacturer(custom);
                            
                            //ap
                            if(type==CTRL_IRAP || type==CTRL_RS485AP) { 
                                apT0++; 
                            }
                            //ac
                            if(type==CTRL_IRAC || type==CTRL_RS485AC) { 
                                acT0++; 
                            }
                            //relay
                            if(type==CTRL_RS485RELAY) {
                                relayT0++; 
                            }
                            //aircon
                            if(type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON || type==CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) { 
                                airconT0++; 
                                if(type==CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) airconRS485T0++;
                            }

                            //if(apType >= 0 && apType != type) {
                            //    ret.canMultyAp = false;
                            //}

                            apType = type;
                        }
                    }
                }

                if(relayT0 > 0) {
                    ret.canMultyAp = false;
                }
            }

            //Check Circulators
            if(acs != null) {
                if(Object.keys(acs).includes("relay")) {
                    apT1 = acs.ap.total;
                    acT1 = acs.ac.total;
                    relayT1 = acs.relay.total;
                    airconT1 = acs.aircon.total;
                    airconRS485T1 = acs.aircon.totalRS485;
                } else {
                    for(let i=0; i<acs.length; i++) {
                        let acType = -1;
                        if(Object.keys(acs[i]).includes("irCode")) {
                            let custom = acs[i].irCode;
                            //custom = parseInt(custom, 16);
                            
                            //let type = ((custom >> 12) & 0xf);
                            let type = this.apcCtrlMethod(custom);
                            //let manufacturer = (custom >> 4) & 0xff;
                            let manufacturer = this.apcManufacturer(custom);
                            
                            //ap
                            if(type==CTRL_IRAP || type==CTRL_RS485AP) { 
                                apT1++; 
                            }
                            //ac
                            if(type==CTRL_IRAC || type==CTRL_RS485AC) { 
                                acT1++; 
                            }
                            //relay
                            if(type==CTRL_RS485RELAY) {
                                relayT1++; 
                            }
                            //aircon
                            if(type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON || type==CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) { 
                                airconT1++; 
                                if(type==CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) airconRS485T1++; 
                            }

                            //if(acType >= 0 && acType != type) {
                            //    ret.canMultyAc = false;
                            //}

                            acType = type;
                        }
                    }
                }

                if(relayT1 > 0) {
                    ret.canMultyAc = false;
                }
            }
            
            ret.totalAp = apT0 + apT1;
            ret.totalAc = acT0 + acT1;
            ret.totalRelay = relayT0 + relayT1;
            ret.totalAircon = airconT0 + airconT1;
            ret.totalRS485Aircon = airconRS485T0 + airconRS485T1;
            ret.total = ret.totalAp + ret.totalAc + ret.totalRelay + ret.totalAircon;

            //console.log("canCtrlMulty="+JSON.stringify(ret));
            //console.log("T:"+ret.total+" P:"+ret.totalAp+" C:"+ret.totalAc+" R:"+ret.totalRelay+" A:"+ret.totalAircon+" CP:"+ret.canMultyAp+" CC:"+ret.canMultyAc);

            return ret;
        }

        Vue.prototype.$apc.apcAcSupportAp = function (irOnCode) {
            let opt = parseInt(irOnCode.substring(4,6), 16);

            return (opt & 1);
        }

        Vue.prototype.$apc.apcModeStr = function (apc, mode) {
            let custom;
            if(apc == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                custom = apc.irCode;
                if(mode == -1) {
                    mode = apc.apcMode;
                }
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", length:0};

            //console.log("apcModeStr    custom:"+custom.toString(16)+" mode:"+mode);

            if(type == CTRL_IRAC || type == CTRL_RS485AC) {
                //공기순환기
                //let __mode = ["--", "환기", "청정", "바이패스", "자동"];

                let __mode;
                let supportAp = this.apcAcSupportAp(apc.irOnCode);
                if(supportAp) {
                    //순환, 청정모드
                    __mode = ["--", "환기", "청정"];
                } else {
                    //순환
                    __mode = ["--", "환기"];
                }

                if(mode >= 0 && mode <= __mode.length) {
                    ret.text = __mode[mode];
                }
                ret.length = __mode.length;
            } else if(type == CTRL_IRAP || type == CTRL_RS485AP) {
                //RS485 순환기
                let __mode = ["--", "청정", "청정V"];
                if(mode >= 0 || mode < __mode.length) {
                    ret.text = __mode[mode];
                }
                ret.length = __mode.length;
            } else if(type == CTRL_RS485AIRCON || type == CTRL_IRAIRCONINV || type == CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                //IR 냉난방기
                let __mode = ["--", "냉방", "난방", "자동"];
                if(mode >= 0 && mode < __mode.length) {
                    ret.text = __mode[mode];
                }
                //ret.length = __mode.length - 1; //except 자동
                ret.length = __mode.length; //with 자동
            } else if(type == CTRL_RS485CHAIR) {
                //온열의자
                let __mode = ["--", "냉열", "온열"];
                if(mode >= 0 && mode < __mode.length) {
                    ret.text = __mode[mode];
                }
                ret.length = __mode.length;
            }

            //console.log("apcModeStr    custom:"+custom.toString(16)+" mode:"+mode+" text:"+ret.text+" length:"+ret.length);

            return ret;
        }

        Vue.prototype.$apc.apcLevelStr = function (custom, level) {
            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", length:0};

            if(type == CTRL_RS485RELAY) {
                //RELAY
                return ret;
            } else if(type == CTRL_RS485CHAIR) {
                if(level > 0) {
                    /*
                    if(manufacturer == DEVICE_MANUF_YULIM_CHAIR2) {
                        ret.text += (level & 1) ? "온열" : "";
                        ret.text += (level & 2) ? "냉열" : "";
                        ret.text += (level & 4) ? "3" : "";
                        ret.text += (level & 8) ? "4" : "";
                    } else 
                    */
                    {
                        ret.text = "ON";
                    }
                } else {
                    ret.text = "OFF";
                }
                return ret;
            } else {
                let __level; 
                if(type == CTRL_IRAP || type == CTRL_IRAC || type == CTRL_RS485AP || type == CTRL_RS485AC) {
                    __level = ["--", "약풍", "중풍", "강풍"];
                    if(level >= 0 && level < __level.length) {
                        ret.text = __level[level];
                    }
                } else {
                    __level = ["--", "약풍", "중풍", "강풍", "자동풍"];
                    if(level >= 0 && level < __level.length) {
                        ret.text = __level[level];
                    }
                }
                ret.length = __level.length;
            }
      
            //console.log("apcLevelStr    custom:"+custom.toString(16)+" level:"+level+" text:"+ret.text+" length:"+ret.length);

            return ret;
        }

        Vue.prototype.$apc.apcTempStr = function (custom, temp) {
            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", dec:-1, length:0};

            //console.log("custom:"+custom+" type:"+type+" manufacturer:"+manufacturer);

            if(type == CTRL_RS485AIRCON || type == CTRL_IRAIRCONINV || type == CTRL_IRAIRCONCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                //IR 냉난방기
                let __temp = ["--", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"];
                if(temp >= 0 && temp < __temp.length) {
                    ret.text = /*"설정" + */__temp[temp] + "℃";
                    if(temp != 0) ret.dec = parseInt(__temp[temp]);
                }
                ret.length = __temp.length;
            } else if(type == CTRL_RS485CHAIR) {
                //Chair
                /*   20   22    24   26  28  30  32  34  36  38  40  42  44  46  48 */
                //let __temp = ["--", "-20", "-15", "-10", "-5", "0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50"];
                let __temp = ["--", "20", "22", "24", "26", "28", "30", "32", "34", "36", "38", "40", "42", "44", "46", "48"];
                if(temp >= 0 && temp < __temp.length) {
                    ret.text = /*"설정" + */__temp[temp] + "℃";
                    if(temp != 0) ret.dec = parseInt(__temp[temp]);
                }
                ret.length = __temp.length;
            }

            //console.log("apcTempStr    custom:"+custom.toString(16)+" temp:"+temp+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcErrorStr = function (apc) {
            let custom = apc.irCode;
            let error = apc.apcError & this.ERROR_MASK;

            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            let ret;

            if(error & this.ERROR_COM) {
                ret = "통신";
            }
            else if(error & this.ERROR_POWER) {
                ret = "전원";
            }

            if(ret != "") {
                ret = ret + "에러";
            }
            
            //if(alarm & this.ERROR_OTHERS) {
            //    ret.push((alarm & 0xf0).toString(16));
            //}

            //console.log("apcTempStr    custom:"+custom.toString(16)+" temp:"+temp+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcAlarmStr = function (apc) {
            let custom = apc.irCode;
            let alarm = apc.apcError & this.ALARM_MASK;

            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            let ret = "";

            if(alarm & this.ALARM_FILTER) {
                if(ret != "") {
                    ret = ret + ",필터";
                } else {
                    ret = ret + "필터";
                }
            }
            if(alarm & this.ALARM_MOTOR) {
                if(ret != "") {
                    ret = ret + ",모터";
                } else {
                    ret = ret + "모터";
                }
            }
            if(alarm & this.ALARM_SENSOR) {
                if(ret != "") {
                    ret = ret + ",센서";
                } else {
                    ret = ret + "센서";
                }
            }
            if(alarm & this.ALARM_DOOR) {
                if(ret != "") {
                    ret = ret + ",도어";
                } else {
                    ret = ret + "도어";
                }
            }

            if(ret != "") {
                ret = ret + "알람";
            }

            //if(alarm & this.ERROR_OTHERS) {
            //    ret.push((alarm & 0xf0).toString(16));
            //}

            //console.log("apcTempStr    custom:"+custom.toString(16)+" temp:"+temp+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcAlarmStr2 = function (apc) {
            let custom = apc.irCode;
            let alarm = apc.apcError & this.ALARM_MASK;

            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            let ret = [];

            if(alarm & this.ALARM_FILTER) {
                ret.push("필터");
            }
            if(alarm & this.ALARM_MOTOR) {
                ret.push("모터");
            }
            if(alarm & this.ALARM_SENSOR) {
                ret.push("센서");
            }
            if(alarm & this.ALARM_DOOR) {
                ret.push("도어");
            }

            return ret;
        }

        Vue.prototype.$apc.apcInTempStr = function (custom, inTemp) {
            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", length:0};

            //console.log("inTemp ================ "+inTemp);

            if(type == CTRL_RS485CHAIR || type == CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                if(inTemp != null && inTemp != undefined) {
                    /*
                    if(inTemp.includes(".")) {
                        ret.text = "현재 " + inTemp;
                    } else {
                        ret.text = "에러 " + inTemp;    
                    }
                    */
                    ret.text = (inTemp/10).toFixed(1);
                }
                ret.length = 0;
            }

            //console.log("apcInTempStr    custom:"+custom.toString(16)+" inTemp:"+inTemp+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcRuleStr = function (custom, rule) {
            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", length:0};

            if(type == CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                if(rule != null && rule != undefined) {
                    ret.text = "룰 " + rule;
                    ret.length = 0;
                }
            }

            //console.log("apcRuleStr    custom:"+custom.toString(16)+" rule:"+rule+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcFilterAlarmStr = function (custom, filterAlarm) {
            if(custom == null) {
                //Only aircon mode setting in AllDeviceStting menu.
                custom = 0xA000;
            } else {
                //custom = parseInt(custom, 16);
            }

            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            let ret = {text:"", length:0};

            if(type == CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                if(filterAlarm != null && filterAlarm != undefined && filterAlarm != "필터알람없음") {
                    ret.text = filterAlarm;
                    ret.length = 0;
                }
            }

            //console.log("apcFilterAlarmStr    custom:"+custom.toString(16)+" filterAlarm:"+filterAlarm+" text:"+ret.text+" length:"+ret.length);
      
            return ret;
        }

        Vue.prototype.$apc.apcIsAircon = function (custom) {
            //custom = parseInt(custom, 16);
            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);

            //console.log("custom="+custom.toString(16)+" type="+type+" manufacturer="+manufacturer);

            if(type == CTRL_IRAIRCONINV || type == CTRL_IRAIRCONCON || type == CTRL_RS485AIRCON || (type == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
              return true;
            } 
            return false;
        }
        
        Vue.prototype.$apc.apcIsAc = function (custom) {
            let type = this.apcCtrlMethod(custom);

            if(type == CTRL_IRAC || type == CTRL_RS485AC) {
              return true;
            } 
            return false;
        }

        Vue.prototype.$apc.apcAirconAp = function (irOnCode) {
            let type = parseInt(irOnCode.substring(2,4), 16);
            type = type & 0x1;
            
            return type;
        }

        Vue.prototype.$apc.apcAirconModbusType = function (irOnCode) {
            let type = parseInt(irOnCode.substring(4,6), 16);
            type = type & 0x1;
            
            return type;
        }
        
        Vue.prototype.$apc.apcIsChair = function (custom) {
            let type = this.apcCtrlMethod(custom);

            if(type == CTRL_RS485CHAIR) {
              return true;
            } 
            return false;
        }
        Vue.prototype.$apc.apcChairType = function (irOnCode) {
            //let type = parseInt(irOnCode.substring(2,4), 16);
            let type = parseInt(irOnCode.substring(0,2), 16);
            type = (type >> 5) & 7;
            
            return type;
        }

        //온열의자의 경우 편차온도 값을 irOnCode 3번째 바이트에 저장한다.
        Vue.prototype.$apc.apcChairDiffTemp = function (irOnCode) {
            //let tempDiff = parseInt(apc.irOnCode.substring(4,6), 16) & 0xff;
            let tempDiff = parseInt(irOnCode.substring(0,2), 16) & 0x1f;
            
            //new system
            //tempDiff = tempDiff * 10;

            //return (tempDiff/10).toFixed(1);

            return tempDiff;
        }

        Vue.prototype.$apc.apcChairHeatIdleTemp = function (irOnCode) {
            return Number('0x'+irOnCode.substring(2,4));
        }

        Vue.prototype.$apc.apcChairCoolIdleTemp = function (irOnCode) {
            return Number('0x'+irOnCode.substring(4,6));
        }

        Vue.prototype.$apc.apcIsCirculator = function (custom) {
            custom = parseInt(custom, 16);
            //let type = ((custom >> 12) & 0xf);
            let type = this.apcCtrlMethod(custom);
            
            if(type == CTRL_RS485AC) {
              return true;
            } 
            return false;
        }

        Vue.prototype.$apc.apcIsIrDevice = function (custom) {
            let type = this.apcCtrlMethod(custom);

            if( type==CTRL_IRAP
                || type==CTRL_IRAC
                || type==CTRL_IRAIRCONINV
                || type==CTRL_IRAIRCONCON
            ) {
                return true;
            }

            return false;
        }

        Vue.prototype.$apc.apcIsRelayDevice = function (custom) {
            let type = this.apcCtrlMethod(custom);

            if(type==CTRL_RS485RELAY) {
                return true;
            }

            return false;
        }

        Vue.prototype.$apc.apcIs485Device = function (custom) {
            let type = this.apcCtrlMethod(custom);

            if( type==CTRL_RS485AP
                || type==CTRL_RS485AC
                || type==CTRL_RS485RELAY
                || type==CTRL_RS485MODBUS
                || type==CTRL_RS485ST
                || type==CTRL_RS485O2
                || type==CTRL_RS485AIRCON
                || type==CTRL_RS485CHAIR
                || type==CTRL_WIFIDIRECT
                || type==CTRL_RS485MONITOR
            ) {
                return true;
            }

            return false;
        }

        Vue.prototype.$apc.apcIsShowDetailAuto = function (apcCtrlMulty) {
            let ac=0, aircon=0;
            
            //console.log("apcCtrlMulty="+JSON.stringify(apcCtrlMulty));
            
            if(this.isSumtur() == true) {
                return false;
            }

            if(apcCtrlMulty != null) {
                if(Object.keys(apcCtrlMulty).includes("selectApc") && apcCtrlMulty.selectApc != null) {
                    let apc = apcCtrlMulty.selectApc;
                    let apcIdx = apcCtrlMulty.selectIdx;

                    for(let i=0; i<apc.length; i++) {
                        if(Object.keys(apc[i]).includes("irCode")) {
                            let custom = apc[i].irCode;
                            custom = parseInt(custom, 16);
                            
                            //let type = ((custom >> 12) & 0xf);
                            let type = this.apcCtrlMethod(custom);
                            
                            //ac
                            if((type==CTRL_IRAC || type==CTRL_RS485AC) && (apcIdx==255 || apcIdx==i)) { 
                                ac++; 
                            }
                            //aircon
                            if((/*type==CTRL_RS485AIRCON || */type==CTRL_IRAIRCONINV || type==CTRL_IRAIRCONCON) && (apcIdx==255 || apcIdx==i)) { 
                                aircon++; 
                            }
                        }
                    }
                } else {
                    //{"total":7,"totalAp":1,"totalAc":1,"totalRelay":1,"totalAircon":4,"canMultyAp":false,"canMultyAc":true}

                    ac = apcCtrlMulty.totalAc;
                    //RS485 Aircon은 모드/온도 조절을 게이트웨이에서 자동으로 진행한다. (미세외치모드인 경우)
                    if(apcCtrlMulty.totalRS485Aircon == 0) {
                        aircon = apcCtrlMulty.totalAircon;
                    }
                }

                /*상세제어는 냉난방기일 경우로 한정한다. */
                if(/*ac || */aircon) {
                    return true;
                }
            }

            return false;
        }

        Vue.prototype.$apc.apcIsShowAuto = function (authInfo) {
            
            if(this.isSumtur() == true/* && authInfo.authority != 0*/) {
                return false;
            }

            return true;
        }
        
        Vue.prototype.$apc.apcIsShowOn = function (authInfo) {
            
            return true;

            /*
            if(this.isSumtur() == true || authInfo.authority == 0) {
                return true;
            }

            return false;
            */
        }

        Vue.prototype.$apc.findDevSetting = function(irOffCode) {
            let irContact = parseInt(irOffCode.substring(0,2), 16) & 0xff;
            let irDevice = parseInt(irOffCode.substring(2,4), 16) & 0xff;
            let irOption = parseInt(irOffCode.substring(4,6), 16) & 0xff;
            let devSetting = {contact: irContact, devIdx: irDevice, nc: false, autoMode: false};

            //console.log(irDevice.toString(16)+"===="+irDeviceNc.toString(16));

            devSetting.nc = (irOption & RELAY_OPTION_NC) ? true : false;
            devSetting.autoMode = (irOption & RELAY_OPTION_AUTO) ? true : false;

            return devSetting;
        }

        Vue.prototype.$apc.findDevSettingByIndex = function(irOffCode, index) {
            let irDevice = parseInt(irOffCode.substring(0,4), 16) & 0xffff;
            let irSetting = parseInt(irOffCode.substring(4,8), 16) & 0xffff;
            let devSetting = {devIdx: -1, nc: false, autoMode: false};

            //console.log(irDevice.toString(16)+"===="+irDeviceNc.toString(16));

            let devIdx = (irDevice >> ((3-index)*4)) & 0xf;
            let setting = (irSetting >> ((3-index)*4)) & 0xf;

            devSetting.devIdx = devIdx;
            devSetting.nc = (setting & RELAY_OPTION_NC) ? true : false;
            devSetting.autoMode = (setting & RELAY_OPTION_AUTO) ? true : false;

            return devSetting;


            // let irDevice = parseInt(irOffCode.substring(0,4), 16) & 0xffff;
            // let irDeviceNc = parseInt(irOffCode.substring(4,8), 16) & 0xffff;
            // let devSetting = {devIdx: -1, nc: false};
            // let matchCount = -1;
            // let devCount = -1;

            // //console.log(irDevice.toString(16)+"===="+irDeviceNc.toString(16));

            // for(let i=15; i>0; i--) {
            //     //console.log("irDevice="+irDevice.toString(16));
            //     //console.log("irDevice2="+(1<<i).toString(16));
                
            //     devCount++;
                
            //     if(irDevice & (1<<i)) {
            //         matchCount++;

            //         //console.log("irDevice="+irDevice.toString(16)+" devCount="+devCount+" index="+index);
                    
            //         if(matchCount == index) {
                        
            //             devSetting.devIdx = devCount;
            //             if(irDeviceNc & (1<<i)) {
            //                 devSetting.nc = true;
            //             }

            //             return devSetting;
            //         }
            //     }
            // }
            // //return -1;
            // return devSetting;
        }
        
        Vue.prototype.$apc.apcRelayDevice = function (apc) {
            let irDevice = parseInt(apc.irOffCode.substring(2,4), 16) & 0xff;
            return irDevice;
        }
        
        Vue.prototype.$apc.apcRelayInfo = function (apc) {
            let status = [];

            //console.log("apc="+JSON.stringify(apc));

            //for(let i=0; i<4; i++) {
                //if(apc.irOffCode == undefined) {
                //    continue;
                //}

                let devSetting = this.findDevSetting(apc.irOffCode);
                let devIdx = devSetting.devIdx;
                
                let relay = {};
                
                relay.devIdx = devIdx;
                //relay.idx = i+1; //중간에 빈 릴레이가 들어갈 수 있으니 Relay #는 순서대로 해야 한다.
                relay.idx = devSetting.contact; /* 1,2,3,4 */
                relay.nc = devSetting.nc;
                relay.autoMode = devSetting.autoMode; //autoMode 여부 추가 230317
                relay.manualMode = 0;
                if(devIdx == RELAY_DEVICE_AUTODOOR2) {
                    relay.width = 110;
                    relay.short = "보조<br>자동";
                    relay.name = "보조자동문";
                    relay.auto = {title:"보조자동문 AUTO", desc:"보조자동문을<br>자동제어<br>합니다."};
                    relay.on = {title:"보조자동문 ON", desc:"보조자동문을<br>강제적으로<br>개방합니다."};
                    relay.off = {title:"보조자동문 OFF", desc:"보조자동문이<br>사용자에 의해<br>열고 닫합니다."};
                }
                else if(devIdx == RELAY_DEVICE_AUTODOOR3) {
                    relay.width = 110;
                    relay.short = "자동<br>문3";
                    relay.name = "자동문3";
                    relay.auto = {title:"자동문3 AUTO", desc:"자동문3을<br>자동제어<br>합니다."};
                    relay.on = {title:"자동문3 ON", desc:"자동문3을<br>강제적으로<br>개방합니다."};
                    relay.off = {title:"자동문3 OFF", desc:"자동문3이<br>사용자에 의해<br>열고 닫합니다."};
                }
                else if(devIdx == RELAY_DEVICE_AUTODOOR4) {
                    relay.width = 110;
                    relay.short = "자동<br>문4";
                    relay.name = "자동문4";
                    relay.auto = {title:"자동문4 AUTO", desc:"자동문4를<br>자동제어<br>합니다."};
                    relay.on = {title:"자동문4 ON", desc:"자동문4를<br>강제적으로<br>개방합니다."};
                    relay.off = {title:"자동문4 OFF", desc:"자동문4가<br>사용자에 의해<br>열고 닫합니다."};
                }
                else if(devIdx == RELAY_DEVICE_AIRCON) {
                    relay.width = 100;
                    relay.short = "실외<br>전원";
                    relay.name = "실외기전원";
                    relay.auto = {title:"실외기전원 AUTO", desc:"실외기전원을<br>자동제어합니다."};
                    relay.on = {title:"실외기전원 ON", desc:"실외기전원을<br>인가합니다."};
                    relay.off = {title:"실외기전원 OFF", desc:"실외기전원을<br>차단합니다."}; 
                }
                else if(devIdx == RELAY_DEVICE_AUTODOOR1) {
                    relay.width = 110;
                    relay.short = "메인<br>자동";
                    relay.name = "자동문열림";
                    relay.auto = {title:"자동문열림 AUTO", desc:"메인자동문을<br>자동제어<br>합니다."};
                    relay.on = {title:"자동문열림 ON", desc:"메인자동문을<br>강제적으로<br>개방합니다."};
                    relay.off = {title:"자동문열림 OFF", desc:"메인자동문이<br>사용자에 의해<br>열고 닫합니다."};
                }
                else if(devIdx == RELAY_DEVICE_AUTODOOROUTSWCON) {
                    relay.width = 100;
                    relay.short = "출입<br>통제";
                    relay.name = "출입통제";
                    relay.auto = {title:"출입통제 AUTO", desc:"출입통제가<br>자동으로<br>동작합니다."};
                    relay.on = {title:"출입통제 ON", desc:"출입통제를<br>시작합니다."};
                    relay.off = {title:"출입통제 OFF", desc:"출입통제를<br>해제합니다."};
                    if(apc.apcRunMode == 0 || apc.apcRunMode == 1) { relay.manualMode = 1; }
                }
                else if(devIdx == RELAY_DEVICE_AIRPURIFIER) {
                    relay.width = 110;
                    relay.short = "공기<br>청정";
                    relay.name = "공기청정기";
                    relay.auto = {title:"공기청정기 AUTO", desc:"공기청정기를<br>자동제어합니다."};
                    relay.on = {title:"공기청정기 ON", desc:"공기청정기를<br>ON 합니다."};
                    relay.off = {title:"공기청정기 OFF", desc:"공기청정기를<br>OFF 합니다."};
                }
                else if(devIdx == RELAY_DEVICE_AIRSTERILIZER || devIdx == RELAY_DEVICE_BUSSTERILIZER) {
                    relay.width = 110;
                    relay.short = "공기<br>살균";
                    relay.name = "공기살균기";
                    relay.auto = {title:"공기살균기 AUTO", desc:"공기살균기를<br>자동제어합니다."};
                    relay.on = {title:"공기살균기 ON", desc:"공기살균기를<br>ON 합니다."};
                    relay.off = {title:"공기살균기 OFF", desc:"공기살균기를<br>OFF 합니다."};
                }
                else if(devIdx == RELAY_DEVICE_O2GENERATOR) {
                    relay.width = 110;
                    relay.short = "산소<br>발생";
                    relay.name = "산소발생기";
                    relay.auto = {title:"산소발생기 AUTO", desc:"산소발생기를<br>자동제어합니다."};
                    relay.on = {title:"산소발생기 ON", desc:"산소발생기를<br>ON 합니다."};
                    relay.off = {title:"산소발생기 OFF", desc:"산소발생기를<br>OFF 합니다."};
                } 
                else if(devIdx == RELAY_DEVICE_LIGHT) {
                    relay.width = 110;
                    relay.short = "조명<br>제어";
                    relay.name = "조명";
                    relay.auto = {title:"조명 AUTO", desc:"조명을<br>자동제어합니다."};
                    relay.on = {title:"조명 ON", desc:"조명을<br>ON 합니다."};
                    relay.off = {title:"조명 OFF", desc:"조명을<br>OFF 합니다."};
                } else if(devIdx == RELAY_DEVICE_AC) {
                    relay.width = 110;
                    relay.short = "공기<br>순환";
                    relay.name = "공기순환기";
                    relay.auto = {title:"공기순환기 AUTO", desc:"공기순환기를<br>자동제어합니다."};
                    relay.on = {title:"공기순환기 ON", desc:"공기순환기를<br>ON 합니다."};
                    relay.off = {title:"공기순환기 OFF", desc:"공기순환기를<br>OFF 합니다."};
                } else {
                    relay.width = 0;
                    relay.nc = false;
                    relay.short = "";
                    relay.name = "";
                    relay.on = {title:"", desc:""};
                    relay.off = {title:"", desc:""};
                }

                if(relay.width > 0) {
                    status.push(relay);
                }
                
                //console.log("apcRelayInfo     devIdx:"+devIdx+" ret:"+JSON.stringify(relay));
            //}

            //console.log("apc:"+JSON.stringify(apc));
            //console.log("status:"+JSON.stringify(status));

            if(status.length > 0) {
                let level = apc.apcLevel;
                for(let i=0; i<status.length; i++) {
                    
                    //등록된 relay 의 idx를 제어에서 사용하기 위함.
                    status[i].cnt = i;
                    
                    /*
                    if(status[i].width > 0) {
                        if(status[i].nc == true) { //default nc connected
                            status[i].value = ((level >> i) & 1) ? false : true;
                        } else {
                            status[i].value = ((level >> i) & 1) ? true : false;
                        }

                        status[i].op = ((level >> i) & 1) ? true : false;
                    }
                    */

                    if(status[i].width > 0) {
                        /*
                        if(status[i].nc == true) { //default nc connected
                            status[i].value = ((level >> (status[i].idx-1)) & 1) ? false : true;
                        } else {
                            status[i].value = ((level >> (status[i].idx-1)) & 1) ? true : false;
                        }

                        status[i].op = ((level >> (status[i].idx-1)) & 1) ? true : false;
                        */

                        if(status[i].nc == true) { //default nc connected
                            status[i].op = (level) ? false : true;
                        } else {
                            status[i].op = (level) ? true : false;
                        }
                    }
                }
            }
            
            //apc.relay = status;

            //console.log("apcRelayInfo ret :"+JSON.stringify(status));

            //return apc;

            return status;
        }
        
        Vue.prototype.$apc.apcAutoMode = function (apc) {
            let ctrlMethod = this.apcCtrlMethod(apc.irCode);

            if(ctrlMethod == CTRL_RS485RELAY) {
                let devSetting = this.findDevSetting(apc.irOffCode);
                if(devSetting.autoMode == false) {
                    return false;
                }
            }

            return true;
        }
        
        Vue.prototype.$apc.apcCtrlData = function (type, deviceIdx, apc, controlData) {
            let ret = {type:'auto', ctrlData:0, error:null};

            let device = apc.apcType;
            let mode = apc.apcMode;
            let level = apc.apcLevel;
            let temp = apc.apcTemp;

            let ctrlMethod = this.apcCtrlMethod(apc.irCode);
            let manufacturer = this.apcManufacturer(apc.irCode);

            console.log("[1] device="+device+" deviceIdx="+deviceIdx+ " type="+type+" mode="+mode+" level="+level+" temp="+temp+" ctrlMethod="+ctrlMethod+" manufacturer="+manufacturer);

            if(0 && ctrlMethod == CTRL_RS485RELAY) {
                console.log("apc.relay.cnt=="+apc.relay.cnt+" apc.relay.idx=="+apc.relay.idx);
                if(apc.relay.cnt != undefined) { //new mode

                    ret.type = type;

                    // if(type == 'auto') {
                    //     ret.type = type;
                    //     mode = 0;
                    //     level = apc.relay.idx;
                    // } else {
                    //     temp = 0;
                    //     if(apc.relay.nc == false) {
                    //         if(type == 'Y' /*apc.relay.value == true*/) {
                    //             ret.type = 'Y';
                    //             mode = 1; //Relay ON
                    //             level = apc.relay.idx;
                    //         } else {
                    //             ret.type = 'Y';
                    //             mode = 0; //Relay OFF
                    //             level = apc.relay.idx;
                    //         }
                    //     } else {
                    //         if(type == 'N' /*apc.relay.value == false*/) {
                    //             ret.type = 'Y';
                    //             mode = 1; //Relay ON
                    //             level = apc.relay.idx;
                    //         } else {
                    //             ret.type = 'Y';
                    //             mode = 0; //Relay OFF
                    //             level = apc.relay.idx;
                    //         }
                    //     }
                    // }

                } else {
                    
                    console.error("RELAY apcCtrlData error!!!!");

                    ret.error = "릴레이 제어 루틴을 체크하세요.";

                    return ret;

                    /*
                    if(type == 'auto') {
                        ret.type = type;
                        mode = 0;
                        level = apc.relayIndex + 1;
                    } else {
                        temp = 0;
                        if(apc.relay[apc.relayIndex].nc == false) {
                            if(apc.relay[apc.relayIndex].value == true) {
                                ret.type = 'Y';
                                mode = 1; //Relay ON
                                level = apc.relayIndex + 1;
                            } else {
                                ret.type = 'Y';
                                mode = 0; //Relay OFF
                                level = apc.relayIndex + 1;
                            }
                        } else {
                            if(apc.relay[apc.relayIndex].value == false) {
                                ret.type = 'Y';
                                mode = 1; //Relay ON
                                level = apc.relayIndex + 1;
                            } else {
                                ret.type = 'Y';
                                mode = 0; //Relay OFF
                                level = apc.relayIndex + 1;
                            }
                        }
                    }
                    */
                }
            } else {

                if(type == 'Y') {
                    //on
                    if(ctrlMethod == CTRL_IRAIRCONINV || ctrlMethod == CTRL_IRAIRCONCON) {

                        if(this.isShelter()==false) {
                            console.log("controlData=="+JSON.stringify(controlData));

                            if((!mode || !temp) && (!Vue.prototype.$common.hasKey(controlData, "amode") || !Vue.prototype.$common.hasKey(controlData, "atemp"))) {
                                ret.error = "기기제어-제어설정에서 냉난방기 모드와 온도를 설정하세요(2).";
                                return ret;
                            }

                            if(!mode) { mode = controlData.amode; }
                            if(!temp) { temp = controlData.atemp; }
                            if(!level) { level = 1; } //약풍
                        } else {
                            if(!level) { level = 1; } //약풍
                            if(!mode) { mode = 1; } //냉방
                            if(!temp) { temp = 10; } //25도
                        }

                        //자연풍모드이면 level도 자연풍레벨으로 설정.
                        if(mode == 3) { 
                            level = 4; 
                        } else {
                            //자연풍모드가 아닌데 자연풍레벨이면 레벨을 1로 바꾼다.
                            if(level == 4) {
                                level = 1;
                            }
                        }

                    } else if(ctrlMethod == CTRL_RS485MODBUS && (manufacturer == DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG)) {
                        if(!level) { level = 1; } //약풍
                        if(!mode) { mode = 1; } //냉방
                        if(!temp) { temp = 10; } //25도

                        //자연풍모드이면 level도 자연풍레벨으로 설정.
                        if(mode == 3) { 
                            level = 4; 
                        } else {
                            //자연풍모드가 아닌데 자연풍레벨이면 레벨을 1로 바꾼다.
                            if(level == 4) {
                                level = 1;
                            }
                        }
                    } else if(ctrlMethod == CTRL_IRAC || ctrlMethod == CTRL_RS485AC) {
                        if(!mode) { mode = 1; } //환기
                        if(!level) { level = 1; } //약풍
                        temp = 0;
                    } else if(ctrlMethod == CTRL_IRAP || ctrlMethod == CTRL_RS485AP) {
                        if(!mode) { mode = 1; } //청정
                        if(!level) { level = 1; } //약풍
                        temp = 0;
                    } else if(ctrlMethod == CTRL_RS485ST || ctrlMethod == CTRL_WIFIDIRECT) {
                        //mode = 0;
                        level = 0;

                        //LED
                        if(temp > 0) {
                            level |= 1;
                        }

                        //UVC
                        if(mode > 0) {
                            level |= 2;
                        }
                    } else if(ctrlMethod == CTRL_RS485CHAIR) {
                        if(!mode) { mode = 2; } //HEAT
                        /*   20   22    24   26  28  30  32  34  36  38  40  42  44  46  48 */
                        if(!temp) { temp = (mode == 1) ? 13 : (mode == 2) ? 3 : 0; } //40도
                        //level = (mode == 1) ? 2 : (mode == 2) ? 1 : 0;
                        level = (mode == 1 || mode == 2) ? 1 : 0;
                    } else {
                        mode = 1;
                        level = 1;
                        temp = 0;
                    }     
                } else if(type == 'N') {
                    //off
                    mode = 0;
                    level = 0;
                    temp = 0;
                } else {
                    //auto
                    if(ctrlMethod == CTRL_IRAIRCONINV || ctrlMethod == CTRL_IRAIRCONCON) {
                        if(this.isShelter()==false) {
                            console.log("controlData=="+JSON.stringify(controlData));

                            if((!mode || !temp) && (!Vue.prototype.$common.hasKey(controlData, "amode") || !Vue.prototype.$common.hasKey(controlData, "atemp"))) {
                                ret.error = "기기제어-제어설정에서 냉난방기 모드와 온도를 설정하세요(3).";
                                return ret;
                            }

                            mode = controlData.amode;
                            temp = controlData.atemp;
                            level = 0;
                        } else {
                            mode = 0;
                            temp = 0;
                            level = 0;
                        } 

                    } else if(ctrlMethod == CTRL_IRAC || ctrlMethod == CTRL_RS485AC || ctrlMethod == CTRL_IRAP || ctrlMethod == CTRL_RS485AP) {
                        mode = 1;
                        level = 0;
                        temp = 0;
                        
                        //숨터순환기의 경우, Auto모드를 실행하면 ON모드로 동작하면서 순환기자동모드로 구동한다.
                        if(this.isSumtur() == true && ctrlMethod == CTRL_RS485AC) {
                            mode = 1;
                            level = 1;
                            type = 'Y';
                        }

                    } else {
                        mode = 1;
                        level = 0;
                        temp = 0;
                    }     
                }

                ret.type = type;
            }

            ret.ctrlData = (temp<<20 | level<<16 | mode<<12 | device<<8 | deviceIdx);

            console.log("[2] device="+device+" deviceIdx="+deviceIdx+ " type="+ret.type+" mode="+mode+" level="+level+" temp="+temp);

            return ret;
        }

        Vue.prototype.$apc.apcDeviceType = function (ctrlData) {
            return (ctrlData >> 8) & 0xf;
        }

        Vue.prototype.$apc.apcDeviceIdx = function (ctrlData) {
            return ctrlData & 0xff;
        }

        Vue.prototype.$apc.apcMode = function (ctrlData) {
            return (ctrlData>>12) & 0xf;
        }

        Vue.prototype.$apc.apcLevel = function (ctrlData) {
            return (ctrlData>>16) & 0xf;
        }

        Vue.prototype.$apc.apcTemp = function (ctrlData) {
            return (ctrlData>>20) & 0xf;
        }

        Vue.prototype.$apc.apcError = function (ctrlData) {
            return (ctrlData>>24) & 0xff;
        }

        Vue.prototype.$apc.apcDecodeCtrlData = function (ctrlData) {
            let dec = {};

            dec.deviceType = this.apcDeviceType(ctrlData);
            dec.deviceIdx = this.apcDeviceIdx(ctrlData);
            dec.mode = this.apcMode(ctrlData);
            dec.level = this.apcLevel(ctrlData);
            dec.temp = this.apcTemp(ctrlData);
            dec.error = this.apcError(ctrlData);

            return dec;
        }

        Vue.prototype.$apc.apcFilterAlarm = function (type, ircode, ctrlData) {
            let error = (ctrlData>>24) & 0xff;
            let custom = ircode;
            //custom = parseInt(custom, 16);
            //let manufacturer = (custom >> 4) & 0xff;
            let manufacturer = this.apcManufacturer(custom);
            if(type == 0) {
                //AP
            } else {
                //AC
                if(manufacturer == DEVICE_MANUF_AT || manufacturer == DEVICE_MANUF_YJ) {
                    /**************************
                    0xE0: No ERROR
                    0xE1: 급기팬에러
                    0xE2: 배기팬에러
                    0xE3: 급배기팬에러
                    0xE4: 통신에러
                    0xE5: 미세먼지센서에러
                    0xE6: CO2센서에러
                    0xE7: 외부온도센서에러
                    0xE8: 전열소자 교환
                    0xE9: 프리&헤파 필터 교환
                    0xEE: 실내온도/습도 센서에러
                    0xEF: 차압센서 에러
                    **************************/

                    if(error == 0xE9) { //프리&헤파 필터 교환
                        return 1;
                    }
                }
            }

            return 0;
        }

        Vue.prototype.$apc.apcCtrlMethod = function (custom) {
            if (typeof custom === 'string') {
                custom = parseInt(custom, 16);
                //console.log("change to int:"+custom);
            }

            //custom = parseInt(custom, 16);

            let type = ((custom >> 12) & 0xf);
            //let type = ((custom >> 8) & 0xff);

            //console.log("custom=="+custom+"  type==="+type);

            return type;
        }

        Vue.prototype.$apc.apcManufacturer = function (custom) {
            if (typeof custom === 'string') {
                custom = parseInt(custom, 16);
                //console.log("change to int:"+custom);
            }

            //custom = parseInt(custom, 16);

            let manufacturer = ((custom >> 4) & 0xff);
            //let manufacturer = ((custom) & 0xff);

            return manufacturer;
        }

        Vue.prototype.$apc.apcSlaveId = function (custom, irOnCode) {
            if (typeof custom === 'string') {
                custom = parseInt(custom, 16);
                //console.log("change to int:"+custom);
            }
            //custom = parseInt(custom, 16);
            
            let slaveId = (custom & 0xf);
            if(slaveId == 0) {
                slaveId = parseInt(irOnCode.substring(0,2), 16);
            }

            //let slaveId = parseInt(irOnCode.substring(0,2), 16);
            
            return slaveId;
        }

        Vue.prototype.$apc.apcSlaveIdInCustom = function (custom) {
            if (typeof custom === 'string') {
                custom = parseInt(custom, 16);
                //console.log("change to int:"+custom);
            }
            //custom = parseInt(custom, 16);
            
            let slaveId = (custom & 0xf);
            
            return slaveId;
        }

        Vue.prototype.$apc.apcSlaveId2 = function (irOnCode) {
            
            let slaveId = parseInt(irOnCode.substring(0,2), 16);
            
            return slaveId;
        }

        Vue.prototype.$apc.apcMonitorDevice = function (custom, irOffCode) {
            if (typeof custom === 'string') {
                custom = parseInt(custom, 16);
                //console.log("change to int:"+custom);
            }
            //custom = parseInt(custom, 16);
            
            let monitorDevice = parseInt(irOffCode.substring(0,2), 16);

            return monitorDevice;
        }

        Vue.prototype.$apc.apcApOnOffCurrent = function (irOffCode) {
            let current = parseInt(irOffCode.substring(0,2), 16);
            
            return current;
        }

        Vue.prototype.$apc.apcImgText = function (school, apc, relay) {
            
        let imgText = {};

        let ctrlMethod = this.apcCtrlMethod(apc.irCode);
        let manufacturer = this.apcManufacturer(apc.irCode);

        if(ctrlMethod == CTRL_RS485RELAY) {
            //console.log("realy="+JSON.stringify(relay));
  
            imgText.comTypeImg = ((apc.apcError & ERROR_COM) != 0) ? require("@/assets/img/relay_error.png") : require("@/assets/img/relay.png");
            //imgText.deviceLevelStr = (relay.value == true) ? "ON" : "OFF";
            imgText.deviceLevelStr = (apc.power == 'Y') ? "ON" : "OFF";
            imgText.deviceModeTempStr = "";
  
            if(relay.devIdx == RELAY_DEVICE_AUTODOOR2) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/sub_door_on.png") : require("@/assets/img/sub_door_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AUTODOOR3) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/third_door_on.png") : require("@/assets/img/third_door_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AUTODOOR4) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/fourth_door_on.png") : require("@/assets/img/fourth_door_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AIRCON) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/aircon_out_power_on.png") : require("@/assets/img/aircon_out_power_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AUTODOOR1) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/main_door_on.png") : require("@/assets/img/main_door_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AUTODOOROUTSWCON) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/acces_control_on.png") : require("@/assets/img/acces_control_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AIRPURIFIER) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/ap_on.png") : require("@/assets/img/ap_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AIRSTERILIZER) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/sterilizer_on.png") : require("@/assets/img/sterilizer_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_O2GENERATOR) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/o2_on.png") : require("@/assets/img/o2_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_BUSSTERILIZER) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/bus_sterilizer_on.png") : require("@/assets/img/bus_sterilizer_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_LIGHT) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/light_on.png") : require("@/assets/img/light_off.png");
            } else if(relay.devIdx == RELAY_DEVICE_AC) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/ac_on.png") : require("@/assets/img/ac_off.png");
            }
          } else if(ctrlMethod == CTRL_RS485MONITOR) {
            //console.log("realy="+JSON.stringify(relay));
            let monitorDevice = this.apcMonitorDevice(apc.irCode, apc.irOffCode);
  
            imgText.comTypeImg = ((apc.apcError & ERROR_COM) != 0) ? require("@/assets/img/relay_error.png") : require("@/assets/img/relay.png");
            //imgText.deviceLevelStr = (relay.value == true) ? "ON" : "OFF";
            if(monitorDevice == MONITOR_CURRENT) {
                let slaveId = this.apcSlaveId(apc.irCode, apc.irOnCode);
                imgText.deviceLevelStr = (apc.apcData2/100).toFixed(2)+"A";
                imgText.deviceModeTempStr = slaveId;
            } else {
                imgText.deviceLevelStr = (apc.power == 'Y') ? "ON" : "OFF";
                imgText.deviceModeTempStr = "";
            }
  
            if(monitorDevice == MONITOR_O2) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/o2_meter_on.png") : require("@/assets/img/o2_meter_off.png");
            }
            else if(monitorDevice == MONITOR_VIBRATION) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/vibration_meter_on.png") : require("@/assets/img/vibration_meter_off.png");
            }
            else if(monitorDevice == MONITOR_CURRENT) {
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/current_meter_on.png") : require("@/assets/img/current_meter_off.png");
            }
          } else {

            //comType
            if(ctrlMethod == CTRL_IRAP || ctrlMethod == CTRL_IRAC || ctrlMethod == CTRL_IRAIRCONINV || ctrlMethod == CTRL_IRAIRCONCON) {
              imgText.comTypeImg = ((apc.apcError & ERROR_COM) != 0) ? require("@/assets/img/ir_error.png") : require("@/assets/img/ir.png");
            } else if(ctrlMethod == CTRL_WIFIDIRECT) {
                imgText.comTypeImg = ((apc.apcError & ERROR_COM) != 0) ? require("@/assets/img/wifidirect_error.png") : require("@/assets/img/wifidirect.png");
            } else {
              imgText.comTypeImg = ((apc.apcError & ERROR_COM) != 0) ? require("@/assets/img/rs485_error.png") : require("@/assets/img/rs485.png");
            }

            //filter error
            imgText.filterError = ((apc.apcError & ALARM_FILTER) != 0) ? true : false;
  
            //deviceType
            imgText.deviceTypeImg = null;
            if(ctrlMethod == CTRL_IRAP || ctrlMethod == CTRL_RS485AP) {
              imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/ap_on.png") : require("@/assets/img/ap_off.png");
            } else if(ctrlMethod == CTRL_IRAC || ctrlMethod == CTRL_RS485AC) {
              imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/ac_on.png") : require("@/assets/img/ac_off.png");
            } else if(ctrlMethod == CTRL_RS485AIRCON || ctrlMethod == CTRL_IRAIRCONINV || ctrlMethod == CTRL_IRAIRCONCON || (ctrlMethod == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
              if(apc.apcMode == 1) { //냉방
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/aircon_cool_on.png") : require("@/assets/img/aircon_cool_off.png");
              } else if(apc.apcMode == 2) { //난방
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/aircon_hot_on.png") : require("@/assets/img/aircon_hot_off.png");
              } else if(apc.apcMode == 3) { //자동
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/aircon_auto_on.png") : require("@/assets/img/aircon_auto_off.png");
              } else {
                imgText.deviceTypeImg = require("@/assets/img/aircon.png");
              }
            } else if(ctrlMethod == CTRL_RS485CHAIR) {
                //chair
                //if(manufacturer == DEVICE_MANUF_YULIM_CHAIR2) {
                    if(apc.apcMode == 1) { //COOL
                        imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/chair_cool_on.png") : require("@/assets/img/chair_cool_off.png");
                    } else if(apc.apcMode == 2) { //HEAT
                        imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/chair_hot_on.png") : require("@/assets/img/chair_hot_off.png");
                    } else {
                        imgText.deviceTypeImg = require("@/assets/img/chair.png");
                    }
                /*
                } else {
                    if(apc.apcMode == 1) { //COOL
                        imgText.deviceTypeImg = (apc.power == 'Y' && (apc.apcLevel & 2)) ? require("@/assets/img/chair_cool_on.png") : require("@/assets/img/chair_cool_off.png");
                    } else if(apc.apcMode == 2) { //HEAT
                        imgText.deviceTypeImg = (apc.power == 'Y' && (apc.apcLevel & 2)) ? require("@/assets/img/chair_hot_on.png") : require("@/assets/img/chair_hot_off.png");
                    } else {
                        imgText.deviceTypeImg = require("@/assets/img/chair.png");
                    }
                }
                */
            } else if(ctrlMethod == CTRL_RS485ST || ctrlMethod == CTRL_WIFIDIRECT) {
                let slaveId = this.apcSlaveId(apc.irCode, apc.irOnCode);
                if(slaveId == 0) {
                    imgText.deviceTypeImg = require("@/assets/img/all_control_on.png");
                } else {
                    if(apc.apcError & ERROR_POWER) {
                        imgText.deviceTypeImg = require("@/assets/img/bus_sterilizer_off_power.png")
                    } else {
                        imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/bus_sterilizer_on.png") : require("@/assets/img/bus_sterilizer_off.png");
                    }
                }
            } else if(ctrlMethod == CTRL_RS485O2) {
                //o2 generator
                imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/o2_on.png") : require("@/assets/img/o2_off.png");
            } else {
              //default for test
              //imgText.deviceTypeImg = (apc.power == 'Y') ? require("@/assets/img/ap_on.png") : require("@/assets/img/ap_off.png");
            }
  
            //levelStr
            if(ctrlMethod == CTRL_RS485ST || ctrlMethod == CTRL_WIFIDIRECT) {
                let slaveId = this.apcSlaveId(apc.irCode, apc.irOnCode);
                if(slaveId == 0) {
                    imgText.deviceLevelStr = "제어";
                } else {
                    imgText.deviceLevelStr = (apc.power == 'Y') ? ("U:"+apc.apcMode) : "OFF";
                }
            } else if(ctrlMethod == CTRL_RS485O2) {
                imgText.deviceLevelStr = (apc.power == 'Y') ? "ON" : "OFF";
            } else if(ctrlMethod == CTRL_RS485CHAIR) {
                imgText.deviceLevelStr = this.apcLevelStr(apc.irCode, apc.apcLevel).text;
            } else {
                let current = this.apcApOnOffCurrent(apc.irOffCode);
                
                if(ctrlMethod == CTRL_IRAP && current > 0 /*manufacturer==DEVICE_MANUF_LG*/) {
                    imgText.deviceLevelStr = (apc.power == 'Y') ? "ON" : "OFF";
                } else {
                    imgText.deviceLevelStr = (apc.power == 'Y') ? this.apcLevelStr(apc.irCode, apc.apcLevel).text : "OFF";
                }
            }
  
            //modeTempStr
            if(ctrlMethod == CTRL_RS485AIRCON || ctrlMethod == CTRL_IRAIRCONINV || ctrlMethod == CTRL_IRAIRCONCON || (ctrlMethod == CTRL_RS485MODBUS && (manufacturer==DEVICE_MANUF_SS || manufacturer==DEVICE_MANUF_LG))) {
                //console.log("apc="+JSON.stringify(apc));
                if(apc.apcMode == 1 || apc.apcMode == 2 || apc.apcMode == 3) {
                    //imgText.deviceModeTempStr = (apc.power == 'Y') ? this.apcTempStr(apc.irCode, apc.apcTemp).text : "";
                    imgText.deviceModeTempStr = (apc.apcTemp != 0) ? this.apcTempStr(apc.irCode, apc.apcTemp).text : "";
                } else {
                    imgText.deviceModeTempStr = "";
                }
            } else if(ctrlMethod == CTRL_RS485CHAIR) {
                if(apc.apcMode == 1 || apc.apcMode == 2) {
                    //imgText.deviceModeTempStr = (apc.power == 'Y') ? this.apcTempStr(apc.irCode, apc.apcTemp).text : "";
                    imgText.deviceModeTempStr = (apc.apcTemp != 0) ? this.apcTempStr(apc.irCode, apc.apcTemp).text : "";
                } else {
                    imgText.deviceModeTempStr = "";
                }
            } else if(ctrlMethod == CTRL_RS485ST || ctrlMethod == CTRL_WIFIDIRECT) {
                let slaveId = this.apcSlaveId(apc.irCode, apc.irOnCode);
                if(slaveId == 0) {
                    imgText.deviceModeTempStr = "전체";
                } else {
                    imgText.deviceModeTempStr = (apc.power == 'Y') ? ("L:"+apc.apcTemp) : "OFF";
                }
            } else {
                imgText.deviceModeTempStr = (apc.power == 'Y') ? this.apcModeStr(apc, -1).text : "";
            }
            
          }

          return imgText;
        }
        
    }


}

export default apc;